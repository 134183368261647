<template>
  <div class="home">
    <!-- 企业介绍 -->
    <div class="companyIntroduction" :style="{ height: height }">
      <div class="introductionText">
        <h1 class="animated fadeInDown">
          <strong>畅你所想</strong>
          <strong>高效生活</strong>
        </h1>
        <h2 class="animated fadeIn">
          <strong>W</strong>ell <strong>O</strong>rganized <strong>L</strong>ife
          <strong>B</strong>ooster
        </h2>
        <h2 class="animated fadeInUp">高效人生加速器</h2>
      </div>
    </div>

    <div class="subText">
      <h2>帮助千万用户快速成长</h2>
      <img src="../assets/imgs/sub_text_icon.png" class="subTextIcon" />
      <div class="subBottomText">
        <img src="../assets/imgs/sub_text_icon_2.png" class="subTextIcon2" />
        <img src="../assets/imgs/sub_text_icon_3.png" class="subTextIcon3" />
        <div class="phtonText show">
          <p>
            有目标的人生才有方向,有规划的人生才更出色。<br />
            如果您希望自己能够快速成长,并愿意为此投入精力做规划、做整理、做总结，那么您就值得拥有<br />
            一款WOLB工具来帮助自己，使自己成为一个对人生负责的人，加速让自己的成就得到更高的提升。
          </p>
        </div>
        <div class="hiden">
          <p>
            有目标的人生才有方向，有规划的人生才更出色。如果您希望自己能够快速成长，并愿意
          </p>
          <p>
            为此投入精力做规划、做整理、做总结，那么您就值得拥有一款WOLB工具来帮助自己，使自己
          </p>
          <p>
            成为一个对人生负责的人，加速让自己的成就得到更高的提升。
          </p>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <HomeSwiper />
    <!-- 产品内容 -->
    <div class="productCont">
      <h2>
        多种特色功能<br class="show" /><span class="hiden">，</span>提升<span
          class="hiden"
          >您的</span
        >工作和生活效率
      </h2>
      <div class="contList">
        <dl v-for="(item, index) in productCont" :key="index">
          <dt><img :src="item.icon" alt="" /></dt>
          <dd>
            <p>{{ item.title }}</p>
            <p>{{ item.text }}</p>
          </dd>
        </dl>
      </div>
    </div>
    <!-- 下载 -->
    <div class="download">
      <img src="../assets/imgs/download_img.png" class="downloadImg hiden" />
      <div class="cont">
        <h2>
          无论您到哪儿<br class="show" /><span class="hiden"> ，</span
          >WOLB都能伴您左右
        </h2>
        <p>您可在iPhone、Android、iPad、Mac、Windows专注高效管理个人工作和生活</p>
        <div class="downloadBtn" @click="toDownload">
          下载应用
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import HomeSwiper from "../components/HomeSwiper.vue";
import offlineIcon from "../assets/imgs/offline_icon.png";
import multideviceIcon from "../assets/imgs/multidevice_icon.png";
import calendarIcon from "../assets/imgs/calendar_icon.png";
import commissionIcon from "../assets/imgs/commission_icon.png";
import incidentIcon from "../assets/imgs/incident_icon.png";
import summaryIcon from "../assets/imgs/summary_icon.png";
import templateIcon from "../assets/imgs/template_icon.png";
export default {
  components: { HomeSwiper },
  name: "Home",
  setup() {
    const router = useRouter();
    const height = (window.innerWidth / 3840) * 1948 + "px";
    const productCont = [
      {
        icon: offlineIcon,
        title: "离线操作",
        text: "没有网络也能使用",
      },
      {
        icon: multideviceIcon,
        title: "多端登录",
        text: "支持手机/平板/电脑同时登录",
      },
      {
        icon: templateIcon,
        title: "人脉模板",
        text: "强大的人脉模板管理",
      },
      {
        icon: incidentIcon,
        title: "互动事件",
        text: "查看和人脉的过往事件",
      },
      {
        icon: summaryIcon,
        title: "事件关联纪要",
        text: "事件内支持添加纪要",
      },
      {
        icon: commissionIcon,
        title: "纪要关联待办",
        text: "纪要内支持添加待办",
      },
      {
        icon: calendarIcon,
        title: "自定义日历设置",
        text: "自定义起始日和起始时间点",
      },
    ];
    const toDownload = () => {
      router.push({ name: "DownloadApp" });
    };
    return {
      height,
      productCont,
      toDownload,
    };
  },
  mounted() {
    // onEditButtonClickedHandler()
  },
  methods: {


  },
};
</script>
<style lang="scss" scoped>
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/

  -webkit-user-select: none; /*webkit浏览器*/

  -khtml-user-select: none; /*早期浏览器*/

  -moz-user-select: none; /*火狐*/

  -ms-user-select: none; /*IE10*/

  user-select: none;
}
img {
  pointer-events: none;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
.home {
  padding-bottom: 146px;
  position: relative;
}
.companyIntroduction {
  color: #fff;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/imgs/top_bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  padding-top: 150px;
  h1 {
    width: 48%;
    font-size: 96px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 10px;
    margin: 0 auto;
    strong {
      font-weight: normal;
    }
  }

  h2 {
    font-size: 30px;
    text-align: center;
    letter-spacing: 2px;
    margin-top: 80px;
    font-weight: normal;
    letter-spacing: 1px;
    word-spacing: 30px;
    color: rgba($color: #fff, $alpha: 0.7);
    strong {
      color: rgba($color: #fff, $alpha: 1);
    }
  }
  h2:nth-of-type(2) {
    margin-top: 25px;
    color: rgba($color: #fff, $alpha: 1);
  }
  p {
    font-size: 18px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 20px;
    line-height: 28px;
  }
}
.productCont {
  background-color: #f9faff;
  padding-top: 126px;
  padding-bottom: 135px;
  h2 {
    color: #192646;
    font-size: 57px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 100px;
  }
  .contList {
    text-align: center;
    dl {
      display: inline-flex;
      vertical-align: top;
      min-width: 388px;
      padding: 0 5px;
      height: 383px;
      background-color: #fff;
      border-radius: 21px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      &:not(:first-child) {
        margin-left: 30px;
      }
      img {
        width: 149px;
      }
      dd {
        p {
          white-space: nowrap;
        }
        p:first-child {
          color: #37474f;
          font-size: 34px;
        }
        p:last-child {
          color: rgba(55, 71, 79, 0.72);
          font-size: 23px;
        }
      }
    }
  }
}
.download {
  position: relative;
  margin-bottom: -180px;
  .downloadImg {
    width: 100%;
  }
  .cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 59px;
      color: #192646;
      font-weight: 500;
    }
    p {
      color: #8c919c;
      font-size: 25px;
      text-align: center;
      margin-top: 37px;
      margin-bottom: 70px;
    }
    .downloadBtn {
      width: 169px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      color: #fff;
      box-shadow: 0px 11px 15px 1px rgba(215, 222, 249, 1);
      border-radius: 34px;
      font-size: 16px;
      cursor: pointer;
      background: #5a76e4;
    }
  }
}
.subText {
  text-align: center;
  background-color: #f9faff;
  position: relative;
  padding: 99px 0;
  h2 {
    color: #192646;
    font-size: 54px;
    margin-bottom: 70px;
  }
  .subTextIcon {
    position: absolute;
    width: 110px;
    left: 103px;
    bottom: 102px;
  }
  .subTextIcon2 {
    position: absolute;
    width: 42px;
    height: 28px;
    top: 0px;
    left: 0px;
  }
  .subTextIcon3 {
    position: absolute;
    width: 42px;
    height: 28px;
    right: 0px;
    bottom: 0px;
  }
  .subBottomText {
    display: inline-block;
    position: relative;
    padding: 30px 45px;
    margin: 0 50px;
    p {
      color: #192646;
      font-size: 23px;
      line-height: 2.1;
    }
  }
}

@media screen and (max-width: 700px) {
  .download .cont .downloadBtn {
    width: 300px;
    height: 110px;
    line-height: 110px;
    border-radius: 55px;
    transform: scale(0.7);
  }
  .subText {
    .subTextIcon {
      width: 140px;
      bottom: 60px;
      left: 60px;
    }
  }
}
@media screen and (max-width: 600px) {
  .companyIntroduction {
    height: 508px !important;
    background-image: url("../assets/imgs/top_bg_phone.png");
    background-size: 100% 100%;
    h1 {
      width: 100%;
      justify-content: center;
      letter-spacing: 3px;
      strong {
        font-size: 64px;
      }
      strong:first-of-type {
        margin-right: 40px;
      }
    }
    h2 {
      margin-top: 35px;
      word-spacing: 15px;
      color: rgba($color: #fff, $alpha: 0.65);
      font-size: 33px;
      strong {
        font-weight: 500;
      }
    }
    h2:nth-of-type(2) {
      margin-top: 14px;
    }
  }
  .subText {
    padding: 70px 0;
    h2 {
      margin-bottom: 40px;
      font-size: 49px;
    }
    .subBottomText {
      padding: 30px 0px;
      margin: 0 40px;
      .phtonText p {
        font-size: 33px;
        letter-spacing: 2px;
        line-height: 52px;
      }
    }
    .subTextIcon {
      width: 110px;
      top: 75px;
      right: 58px;
      bottom: auto;
      left: auto;
    }
    .subTextIcon3 {
      bottom: 23px;
      right: -5px;
    }
    .subTextIcon2 {
      left: 0px;
    }
  }
  .productCont {
    padding-top: 50px;
    padding-bottom: 70px;
    h2 {
      font-weight: 500;
      line-height: 74px;
      margin-bottom: 50px;
      font-size: 49px;
    }
    .contList {
      text-align: left;
      padding-left: 13.3px;
      dl {
        margin-left: 30px;
        text-align: center;
        min-width: 420px;
        box-shadow: 0px 0px 77px 0px rgba(0, 0, 0, 0.06);
        margin-top: 20px;
        img {
          width: 162px;
        }
        dd p:first-child {
          font-size: 38px;
          font-weight: 500;
        }
        dd p:last-child {
          font-size: 32px;
        }
      }
    }
  }
  .download {
    margin-bottom: 0;
    padding-top: 50px;
    .cont {
      position: relative;
      h2 {
        text-align: center;
        line-height: 85px;
        font-size: 49px;
      }
      p {
        font-size: 33px;
        width: 640px;
      }
      .downloadBtn {
        width: 352px;
        height: 141px;
        border-radius: 70px;
        box-shadow: 0px 13px 18px 2px rgba(215, 222, 249, 1);
        font-size: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .home {
    padding-bottom: 66px;
  }
}
</style>
