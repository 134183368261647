<template>
  <div class="bottomText">
    <div class="contactMore">
      <dl class="hiden">
        <dt @click="intoProduct">产品</dt>
        <dd @click="intoProduct">日历事件</dd>
        <dd @click="intoProduct">人脉</dd>
        <dd @click="intoProduct">纪要</dd>
        <dd @click="intoProduct">待办</dd>
      </dl>
      <dl class="hiden">
        <dt @click="intoAboutUs">公司</dt>
        <dd @click="intoAboutUs">公司简介</dd>
        <dd @click="intoAboutUs">公司愿景/使命</dd>
        <dd @click="intoAboutUs">价值观</dd>
      </dl>
      <dl>
        <dt>联系我们</dt>
        <dd>公司：北京畅效科技有限公司</dd>
        <!-- <dd>邮箱：cxIntangibleAssets@zmail.smzdm.com</dd> -->
        <dd>电话：+86 13001073695</dd>
        <dd class="showBlock">客服微信：wolb001</dd>
        <dd>微信公众号：WOLB_WOLB</dd>
        <dd>地址：北京市丰台区汽车博物馆东路1号院3号楼4层512</dd>
      </dl>
      <dl class="hiden">
        <dt>在线客服</dt>
        <dd>
          <img src="../assets/imgs/qr_relation.png" alt="" />
        </dd>
        <dd>扫码添加客服微信</dd>
      </dl>
    </div>
    <div class="copyright">
      <p>
        <span>
          Copyright © 2021-2024
          <a href="http://www.w-o-l-b.com">www.w-o-l-b.com</a>. All Rights
          Reserved.
        </span>
        <span>
          畅效科技 版权所有 |
          <a href="http://beian.miit.gov.cn" target="_blank" style="display:inline-block;">京ICP备2021010448号-1</a>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import { watch } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "PageFooter",
  props: {
    footerColor: {
      type: String,
      reuquired: false,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    let color = props.footerColor;
    const intoProduct = () => {
      router.push({ name: "Product" });
    };
    const intoAboutUs = () => {
      router.push({ name: "AboutUs" });
    };
    watch(props, (newProps) => {
      color = newProps.footerColor;
      $(".contactMore").css({ background: color });
    });
    return {
      color,
      intoProduct,
      intoAboutUs,
    };
  },
};
</script>
<style lang="scss" scoped>
.copyright {
  width: 100%;
  line-height: 86px;
  background-color: #f9faff;
  text-align: center;
  font-size: 16px;
  color: #8c919c;

  a {
    color: #8c919c;
  }
}

.contactMore {
  display: flex;
  justify-content: center;
  color: #192646;
  position: relative;
  z-index: 2;
  padding: 40px 20% 0;

  dt {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  dd {
    line-height: 1.8;
    font-size: 16px;
  }

  dl {
    flex: 1;
  }

  dl:nth-child(3) {
    flex: 3.5;
    // margin-left: 40%;
    margin-right: 50px;
  }

  dl:nth-child(2) {
    flex: 1.2;
  }

  dl:last-child {
    img {
      width: 130px;
    }

    dd:last-child {
      color: #192646;
      font-size: 12px;
      padding-left: 5px;
    }
  }

  dl:nth-child(-n + 2) dd,
  dl:nth-child(-n + 2) dt {
    cursor: pointer;
  }
}

@media screen and (max-width: 1100px) {
  .contactMore {
    padding: 40px 10% 0;
  }
}

@media screen and (max-width: 730px) {
  .contactMore {
    padding: 40px 5% 0;
  }

  .copyright {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {

  .contactMore,
  .copyright {
    background-color: #fff !important;
    border-top: 1px solid #eeeeee;
  }

  .copyright {
    font-size: 30px;
    padding: 20px 0px;
    line-height: 40px;
    color: #969696;

    span {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .contactMore {
    dt {
      font-size: 38px;
    }

    dd {
      font-size: 33px;
      color: #969696;
    }
  }
}
</style>
